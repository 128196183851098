<template>
  <div class="sqbForm">
    <el-dialog
      :visible.sync="ckfjData.ckfjDialogVisible"
      center
      :title="'查看附件'"
      custom-class="dialog-class"
      width="1100px"
      @close="closeDialog"
    >
      <!-- 选中人信息 -->
      <el-form label-width="110px" :model="ckfjData" ref="mainForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label="权利人名称：" prop="qlrmc">
              <el-input disabled v-model="ckfjData.qlrmc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="证件类型：" prop="zjzl">
              <dict-select disabled v-model="ckfjData.zjzl" type="证件种类"></dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="证件号码：" prop="zjh">
              <el-input disabled v-model="ckfjData.zjh"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="13">
            <el-form-item label="不动产权证号：" prop="bdcqzh">
              <el-input disabled v-model="ckfjData.bdcqzh"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="状态：" prop="tddjzt">
              <dict-select disabled v-model="ckfjData.tddjzt" type="权属状态"></dict-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="layout-sqbForm">
        <div class="layout-left">
          <!-- 左下部分 显示材料分类部分 -->
          <div class="layout-tree-file">
            <div class="layout-tree-file-title">
              <span>附件材料</span>
            </div>
            <!-- 材料导航 -->
            <div class="layout-ul">
              <ul class="clfile">
                <li v-for="(item, index) in ckfjData.fileData" :key="index">
                  <div class="layout-li" :class="{ active: choosefile.typeRelId === item.id }">
                    <i class="icon el-icon-folder-opened"></i>
                    <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                      <span>{{ item.name }}</span>
                    </el-tooltip>
                  </div>
                  <ul class="layout-li-fileul" v-show="item.bizFileUrlRelations">
                    <li v-for="(img, pindex) in item.bizFileUrlRelations" :key="pindex" @click="lookImg(img)">
                      <div class="layout-li" :class="{ active: choosefile.id === img.id }">
                        <i class="icon el-icon-tickets"></i>
                        <el-tooltip class="item" effect="dark" :content="img.fileName" placement="top-start">
                          <span>{{ img.fileName }}</span>
                        </el-tooltip>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 右侧内容显示 -->
        <div class="layout-right">
          <!-- 右侧显示材料附件 -->
          <div class="layout-file">
            <div class="form-top">
              <div class="title">附件查看</div>
              <div style="float: right">
                <el-button v-show="choosefile.fileUrl" type="primary" @click="DownloadAttachment">打印</el-button>
              </div>
            </div>
            <!-- <div class="layout-tree-file-title">
              <span>附件查看</span>
            </div> -->
            <div class="jiantou1" @click="cutAway('prev')"><i class="el-icon-arrow-left"></i></div>
            <div class="jiantou2" @click="cutAway('next')"><i class="el-icon-arrow-right"></i></div>
            <div class="viewer" v-if="choosefile.fileName.indexOf('.pdf') >= 0">
              <iframe :src="fileImgUrl + choosefile.fileUrl"></iframe>
            </div>
            <div class="viewer" v-if="choosefile.fileName.toLowerCase().indexOf('.mp4') >= 0">
              <video controls :src="fileImgUrl + choosefile.fileUrl">
                <source type="video/mp4" />
              </video>
            </div>
            <div id="videoPlayerBox" class="viewer" v-show="choosefile.fileName.toLowerCase().indexOf('.mov') >= 0">
              <!-- <video ng-src="{{choosefile.fileUrl}}" controls="controls"></video> -->
            </div>
            <div
              class="viewer"
              v-if="
                choosefile.fileName.indexOf('.pdf') < 0 &&
                choosefile.fileName.toLowerCase().indexOf('.mp4') < 0 &&
                choosefile.fileName.toLowerCase().indexOf('.mov') < 0 &&
                choosefile.fileUrl
              "
            >
              <div class="img-tool">
                <div class="img-tool-btn">
                  <i class="icon el-icon-zoom-in" @click="imgToUp(25, choosefile)" title="放大"></i>
                  <i class="icon el-icon-zoom-out" @click="imgToUp(-25, choosefile)" title="缩小"></i>
                  <i class="icon el-icon-refresh-right" @click="rotate(choosefile)" title="旋转"></i>
                  <i class="icon el-icon-refresh" @click="imgToUp(100, choosefile, 'init')" title="复位"></i>
                </div>
              </div>
              <div class="liulan">
                <div>
                  <div class="movableItem" @mousedown="dragMove($event, 1)">
                    <img
                      draggable="false"
                      oncontextmenu="return false;"
                      onselectstart="return false;"
                      class="draggableImg"
                      :class="'img-' + choosefile.id"
                      ref="img"
                      :style="{ transform: choosefile.transform, width: width, height: height }"
                      :src="fileImgUrl + choosefile.fileUrl"
                      @mousewheel="rollImg($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { localDataUser } from '@iebdc/utils/local-data';

export default {
  name: 'viewAttachments',
  props: {
    ckfjData: {
      type: Object,
      default() {
        return {};
      }
    },
    fileImgUrl: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      // fileImgUrl: window.CONFIG.FILE_FC_ImgUrl,
      fileData: [],
      choosefile: {
        fileName: ''
      },
      user: localDataUser.get(),
      //图片实际长宽
      img: {},
      //当前可切换的图片节点
      imgCutList: [],
      //材料查看图片放大缩小
      // 图片显示默认大小
      width: $('.layout-file').width() + 'px',
      height: $('.layout-file').height() + 'px',
      // 可缩小倍数,为空则可无限缩小
      minification: 3,
      // 可放大倍数 为空则可无限放大
      magnification: ''
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.$emit('changeckfjData', false);
    },
    //下载
    DownloadAttachment() {
      if (this.choosefile.fileName.indexOf('.pdf') >= 0) {
        window.open(this.fileImgUrl + this.choosefile.fileUrl);
      } else if (this.choosefile.fileName.toLowerCase().indexOf('.mp4') >= 0) {
        this.$message.warning('视频无法打印!');
      } else {
        this.$print(this.$refs.img); // 使用
      }
    },
    //查看图片
    lookImg(img) {
      this.choosefile = {
        imgSize: 100,
        num: 0,
        transform: `'rotate(90deg) scale(1, 1)'`
      };
      this.choosefile = Object.assign(this.choosefile, img);
    },
    //图片旋转
    rotate(item) {
      item.num++;
      // item.transform = `rotate(${item.num * 90}deg) scale(1, 1)`;
      $('.draggableImg').css('transform', `rotate(${item.num * 90}deg) scale(1, 1)`);
    },
    //图片上下切换
    cutAway(type) {
      this.imgCutList = [];
      $('.layout-li-fileul li').map((index, item) => {
        for (let indexChild = 0; indexChild < item.children.length; indexChild++) {
          this.imgCutList.push(item.children[indexChild]);
        }
      });
      this.imgCutList.map((item, index) => {
        if ($(item).is('.active')) {
          if (type == 'prev') {
            let prev = index - 1 < 0 ? this.imgCutList.length - 1 : index - 1;
            $(this.imgCutList[prev]).parent().click();
          } else {
            let nextClick = index + 1 > this.imgCutList.length - 1 ? 0 : index + 1;
            $(this.imgCutList[nextClick]).parent().click();
          }
        }
      });
    },
    //图片放大缩小还原
    imgToUp(num, item, type) {
      //图片重置
      if (type) {
        //获取图片原尺寸
        var currentImg = new Image();
        currentImg.src = this.fileImgUrl + item.fileUrl;
        $('.draggableImg').width(currentImg.width + 'px');
        $('.draggableImg').height(currentImg.height + 'px');
        $('.draggableImg').css('transform', '');

        //设置为原始偏移值
        $('.draggableImg').css('top', '0px');
        $('.draggableImg').css('left', '0px');
        $('.movableItem').css('top', '0px');
        $('.movableItem').css('left', '0px');
        return;
      }
      let img = this.$refs.img;
      if (item.imgSize === 100) {
        let oWidth = img.width; //取得图片的实际宽度
        let oHeight = img.height; //取得图片的实际高度
        this.img.oCopyWidth = oWidth;
        this.img.oCopyHeight = oHeight;
      }
      if (num === 100) {
        item.imgSize = 100;
      } else {
        item.imgSize = item.imgSize + num;
      }
      let oW = (this.img.oCopyWidth * item.imgSize) / 100;
      let oH = (this.img.oCopyHeight * item.imgSize) / 100;
      $('.draggableImg').width(oW + 'px');
      $('.draggableImg').height(oH + 'px');
    },
    //拖拽
    dragMove() {
      // var ie = document.all;
      // console.log(ev / 0);

      // console.log("点击图片");
      var nn6 = document.getElementById && !document.all;
      var isdrag = false;
      var y, x;
      var nTY, nTX;
      var oDragObj;
      function moveMouse(e) {
        // console.log("oDragObj.style.top1", oDragObj.style.top);
        if (isdrag) {
          oDragObj.style.top = (nn6 ? nTY + e.clientY - y : nTY + event.clientY - y) + 'px';
          oDragObj.style.left = (nn6 ? nTX + e.clientX - x : nTX + event.clientX - x) + 'px';

          return false;
        }
      }
      function initDrag(e) {
        // console.log("点击图片initDrag");
        var oDragHandle = nn6 ? e.target : event.srcElement;
        var topElement = 'HTML';
        while (oDragHandle.tagName !== topElement && oDragHandle.className != 'movableItem') {
          oDragHandle = nn6 ? oDragHandle.parentNode : oDragHandle.parentElement;
        }
        if (oDragHandle.className === 'movableItem') {
          isdrag = true;
          oDragObj = oDragHandle;
          // 父元素宽高
          let width = e.path[2].offsetWidth;
          let height = e.path[2].offsetHeight;
          // console.log(width, height);
          // console.log(oDragObj.style);
          // 这里判断第一次获取不到style 样式 默认为 居中1%
          if (oDragObj.style.top == '') {
            nTY = parseInt((1 * height) / 100 + 0);
            nTX = parseInt((1 * width) / 100 + 0);
          } else {
            nTY = parseInt(oDragObj.style.top + 0);
            nTX = parseInt(oDragObj.style.left + 0);
          }
          y = nn6 ? e.clientY : event.clientY;
          x = nn6 ? e.clientX : event.clientX;
          oDragObj.style.cursor = 'move';
          document.onmousemove = moveMouse;
          return false;
        }
      }
      document.onmousemove = initDrag;
      // document.onmouseup = new Function("isdrag=false");
      document.onmouseup = function (e) {
        isdrag = false;
        document.onmousemove = null;
        document.onmouseup = null;
        var oDragHandle = nn6 ? e.target : event.srcElement;
        var topElement = 'HTML';
        while (oDragHandle.tagName !== topElement && oDragHandle.className != 'movableItem') {
          oDragHandle = nn6 ? oDragHandle.parentNode : oDragHandle.parentElement;
        }
        if (oDragHandle.className === 'movableItem') {
          oDragObj = oDragHandle;
          oDragObj.style.cursor = 'Default';
        }
      };
      ev = event || window.event;

      // 取消事件冒泡行为
      // window.event ? (window.event.cancelBubble = true) : ev.stopPropagation();
    },
    // 缩放
    rollImg() {
      // var oImg = document.getElementsByClassName("movableItem")[0];
      var oImg = this.$refs.img;
      // console.log(
      //   "length",
      //   document.getElementsByClassName("movableItem").length
      // );
      // console.log("oImg", oImg);
      var _this = this;
      function fnWheel(obj, fncc) {
        obj.onmousewheel = fn;
        if (obj.addEventListener) {
          obj.addEventListener('DOMMouseScroll', fn, false);
        }
        function fn(ev) {
          var oEvent = ev || window.event;
          var down = true;
          if (oEvent.detail) {
            down = oEvent.detail > 0;
          } else {
            down = oEvent.wheelDelta < 0;
          }
          if (fncc) {
            fncc.call(this, down, oEvent);
          }
          if (oEvent.preventDefault) {
            oEvent.preventDefault();
          }
          return false;
        }
      }
      fnWheel(oImg, function (down, oEvent) {
        var oldWidth = this.offsetWidth;
        var oldHeight = this.offsetHeight;
        var oldLeft = this.offsetLeft;
        var oldTop = this.offsetTop;
        var parent = oEvent.path[2];
        // 获取父元素距离页面可视区域的位置
        var parentLeft = parent.getBoundingClientRect().left;
        var parentTop = parent.getBoundingClientRect().top;
        // 比例 = （点击位置距离可视窗口位置 - 父元素距离可视窗口位置 - 相对定位的left）/ 本身宽度
        var scaleX = (oEvent.clientX - parentLeft - oldLeft) / oldWidth; //比例
        var scaleY = (oEvent.clientY - parentTop - oldTop) / oldHeight;

        let nowWidth = this.style.width.split('p')[0];
        let initWidth = _this.width.split('p')[0];
        let initHeight = _this.height.split('p')[0];

        let miniFlag = true;
        let magniFlag = true;
        if (_this.minification) {
          // 限制缩小范围
          if (nowWidth <= parseInt(initWidth / _this.minification)) {
            miniFlag = false;
            // console.log("限制缩小范围");
            // console.log(
            //   "限制缩小范围",
            //   nowWidth,
            //   parseInt(initWidth / _this.minification)
            // );
            this.style.width = parseInt(initWidth / _this.minification) + 'px';
            this.style.height = parseInt(initHeight / _this.minification) + 'px';
          }
          if (_this.magnification) {
            // 限制放大范围
            if (nowWidth >= parseInt(initWidth * _this.magnification)) {
              magniFlag = false;
              // console.log("限制放大范围");
              this.style.width = parseInt(initWidth * _this.magnification) + 'px';
              this.style.height = parseInt(initHeight * _this.magnification) + 'px';
            }
          }
        }

        if (down && miniFlag) {
          // console.log("缩小");
          this.style.width = parseInt(this.offsetWidth * 0.9) + 'px';
          this.style.height = parseInt(this.offsetHeight * 0.9) + 'px';
        } else if (!down && magniFlag) {
          // console.log("放大");
          this.style.width = parseInt(this.offsetWidth * 1.1) + 'px';
          this.style.height = parseInt(this.offsetHeight * 1.1) + 'px';
        }
        var newWidth = this.offsetWidth;
        var newHeight = this.offsetHeight;

        // 新的相对位置left = 原先的相对位置left - 比例 *（本身新的宽度-旧的宽度）
        this.style.left = Math.round(this.offsetLeft - scaleX * (newWidth - oldWidth)) + 'px';
        this.style.top = Math.round(this.offsetTop - scaleY * (newHeight - oldHeight)) + 'px';
      });
    }
  },
  mounted() {
    this.ckfjData.fileData.map((item) => {
      if (item.bizFileUrlRelations && item.bizFileUrlRelations.length > 0) {
        item.bizFileUrlRelations.map((itemChild) => {
          this.fileData.push(itemChild);
        });
      }
    });
    this.choosefile = Object.assign({ imgSize: 100, num: 0, fileName: '' }, this.fileData[0]);
    if (this.user.userCategory === 'NORMAL') {
      this.ckfjData.zjzl = this.user.zjzl;
      this.ckfjData.zjh = this.user.zjh;
    } else if (this.user.userCategory === 'KFS') {
      // this.ckfjData.zjzl = this.user.qyzjzl || '7'; // 开发商企业证件类型默认营业执照
      this.ckfjData.zjzl = '6';
      this.ckfjData.zjh = this.user.qyzjh;
    }
  }
};
</script>

<style lang="scss" scoped>
.layout-sqbForm {
  display: flex;
  .layout-left {
    flex: 1;
    overflow-y: auto;
    // overflow: hidden;
    width: 30%;
    height: 580px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 3px 3px rgba(22, 115, 255, 0.1);
    // padding: 8px;
    .layout-tree-file-title {
      height: 40px;
      line-height: 30px;
      padding: 5px 8px;
      font-size: 16px;
      border-bottom: 1px solid #e9eff5;
      button {
        background-color: #0e90fe;
        float: right;
        padding: 3px 8px;
        border: none;
        min-width: 10px;
        margin-left: 5px;
      }
    }

    .layout-ul {
      width: 100%;
      height: 100%;
      margin: 0px;
      padding: 0px;
      overflow: auto;
      color: #7f8fa8;
      > ul {
        padding: 0px;
        margin: 10px 10px 0px 10px;
      }
      .sqb {
        > li > .layout-li {
          color: #0e90fe;
          i {
            margin-right: 5px;
          }
        }
        > li .active {
          color: #0e90fe;
        }
      }
      .clfile {
        > li .active {
          color: #0e90fe;
        }
        > li .layout-li {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          i {
            margin-right: 5px;
          }
        }
      }
      .layout-li {
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        white-space: nowrap;
      }
      .layout-li-sqbul {
        border-top: 1px solid #eee;
        padding: 10px 20px 0px 20px;
        li {
          border-left: 1px solid #eee;
          list-style-type: none;
          span {
            padding-left: 20px;
          }
        }
      }
      .layout-li-fileul {
        border-left: 1px solid #eee;
        padding: 0px 0px 0px 10px;
        margin-left: 16px;
        li {
          background-color: #f3f8ff;
          list-style-type: none;
        }
      }
    }
  }

  .layout-right {
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 580px;
    width: 68%;
    // margin: 10px 10px 0px 0px;
    overflow: hidden;
    .layout-tree-file-title {
      height: 40px;
      line-height: 30px;
      padding: 5px 8px;
      font-size: 16px;
      border-bottom: 1px solid #e9eff5;
      button {
        background-color: #0e90fe;
        float: right;
        padding: 3px 8px;
        border: none;
        min-width: 10px;
        margin-left: 5px;
      }
    }
    .layout-tool {
      margin-left: 8px;
      height: 50px;
      width: 100%;
      background-color: #fff;
    }
    .drag {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 3px;
      cursor: ew-resize;
    }
    .layout-file {
      width: 100%;
      height: 100%;
      flex: 2;
      background-color: #fff;
      padding: 8px;
      // margin-left: 8px;
      position: relative;
      //图片箭头翻页
      .jiantou1 {
        position: absolute;
        top: 50%;
        left: 0;
        width: 50px;
        height: 50px;
        z-index: 9999;
        background-color: rgba(14, 144, 254, 0.6);
        font-size: 30px;
        font-weight: bold;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // opacity: 0;
        border-radius: 50%;
      }
      // .jiantou1:hover{
      //   opacity: 1;
      // }
      .jiantou2 {
        position: absolute;
        top: 50%;
        right: 0;
        width: 50px;
        height: 50px;
        z-index: 9999;
        background-color: rgba(14, 144, 254, 0.6);
        font-size: 30px;
        font-weight: bold;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // opacity: 0;
        border-radius: 50%;
      }
      // .jiantou2:hover{
      //   opacity: 1;
      // }
      .form-top {
        width: 100%;
        padding: 10px 0px;
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        overflow: hidden;
        .title {
          border-left: 3px solid rgba(14, 144, 254, 1);
          padding-left: 5px;
          color: #333;
          font-size: 16px;
          float: left;
          //   display: inline-block;
        }

        .tool {
          float: right;
          padding: 0px;
          margin: 0px;
          margin-left: 27px;
          cursor: pointer;
          span {
            padding: 1px 5px 0px 5px;
          }
        }
      }
      .viewer {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        iframe {
          width: 100%;
          height: 70vh;
        }
        video {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .liulan {
        flex: 1;
        height: 100%;
        overflow: hidden;
        div {
          width: 100%;
          height: 100%;
          // overflow: auto;
          position: relative;
          img {
            position: absolute;
            margin: 0px auto;
            transform-origin: '0 0';
          }
        }
      }
      .img-tool {
        height: 40px;
        .img-tool-btn {
          margin: 0px;
          padding: 8px 0px 8px 0px;
          i {
            margin-right: 15px;
            font-size: 20px;
            padding: 5px;
            color: #0e90fe;
          }
          i:hover {
            color: #fff;
            background-color: #0e90fe;
            border-radius: 2px;
          }
        }
      }
    }
  }
  // 滚动条默认样式定制
  ::-webkit-scrollbar-track-piece {
    background: #d3dce6;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #99a9bf;
  }
}
</style>
